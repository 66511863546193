import { getEnvironment } from 'utilities/commonUtils';
import { OPT_OUT_FEEDBACK_FORM_LINKS } from './links';

const ENVIRONMENTS = {
  DEV: 'dev',
  STG: 'stg',
  PROD: 'prod',
};

const ENV = getEnvironment(ENVIRONMENTS);

const URL_DOMAIN_ENV = ENV !== ENVIRONMENTS.PROD ? `-${ENV}` : '';

const BASE_URL: {
  [key: string]: string;
} = {
  dev: 'https://idp.auth-dev.autodesk.com',
  stg: 'https://idp.auth-staging.autodesk.com',
  prod: 'https://idp.auth.autodesk.com',
};

const OKTA_DEFAULT_DOMAIN: {
  [key: string]: string;
} = {
  dev: 'https://autodesk-dev.oktapreview.com',
  stg: 'https://autodesk-staging.oktapreview.com',
  prod: 'https://autodesk-prod.oktapreview.com',
};

const OKTA_CUSTOM_DOMAIN: {
  [key: string]: string;
} = {
  dev: 'https://authn-dev.autodesk.com',
  stg: 'https://authn-staging.autodesk.com',
  prod: 'https://authn.autodesk.com',
};

const ENDPOINTS = {
  BASE_URL: window.idp?.env?.constants?.BASE_URL ?? BASE_URL[ENV],
  API_BASE_PATH: 'as',
  FLOWS: 'flows',
  PF_LOGOUT: 'as/logout',
  CUSTOM_OKTA_SESSION_URL: OKTA_CUSTOM_DOMAIN[ENV] + '/api/v1/sessions/me',
  DEFAULT_OKTA_SESSION_URL: OKTA_DEFAULT_DOMAIN[ENV] + '/api/v1/sessions/me',
  IDP_OPT_OUT_FEEDBACK_FORM: OPT_OUT_FEEDBACK_FORM_LINKS[ENV],
};

const DEVELOPER_API_BASE_URL = `https://developer${URL_DOMAIN_ENV}.api.autodesk.com`;

const AUTHORIZE_URL_PATH = '/authentication/v2/authorize';

const WELL_KNOWN_CONFIG_PATH = '/.well-known/openid-configuration';

// These URL params are specific to Logout cases
const URL_PARAMS = {
  TARGET_RESOURCE: 'TargetResource',
  POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
  IDSDK_DESKTOP_LOGOUT: 'from_desktop',
};

// Transition config for Main Card (Content box) on the screen
const TRANSITION_CONFIG = {
  CLASSES: {
    fadeInLeft: 'fade-in-left',
    fadeInRight: 'fade-in-right',
    fadeOutLeft: 'fade-out-left',
    fadeOutRight: 'fade-out-right',
    translateLeft: 'translate-left',
    translateRight: 'translate-right',
  },
  DURATION: 250,
};

const FADE_TIMEOUT = 100;

const INPUT_FOCUS_DELAY_MS = 350;

// Common timeout for all collapse transitions
const COLLAPSE_TRANSITION_TIMEOUT = 500;

const UI_PROMPTS = {
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  SHOW_TOS: 'SHOW_TOS',
  HIDE_OPTIN: 'HIDE_OPTIN',
};

const OAUTH_PARAMS = {
  UI_LOCALES: 'ui_locales',
  PROMPT: 'prompt',
  RESPONSE_TYPE: 'response_type',
  CLIENT_ID: 'client_id',
  REDIRECT_URI: 'redirect_uri',
  SCOPE: 'scope',
  STATE: 'state',
  CODE_CHALLENGE: 'code_challenge',
  CODE_CHALLENGE_METHOD: 'code_challenge_method',
  REDIRECT_URL: 'redirect_url',
};

const OAUTH_RESPONSE_TYPES = {
  CODE: 'code',
};

const OAUTH_PROMPTS = {
  LOGIN: 'login',
  NONE: 'none',
  CREATE: 'create',
};

const OAUTH_CODE_CHALLENGE_METHODS = {
  S256: 'S256',
};

const AUTODESK_TEXT = 'Autodesk';

const UI_LOCALE_COOKIE = 'uiLocaleData';

const IDP_OPT_OUT = `${BASE_URL[ENV]}/accounts/v1/idp-opt-out`;

const LINK_LOCALISATION_PATTERN = {
  APPEND_LOCALE: 'append_locale',
  REPLACE_DEFAULT_LOCALE: 'replace_default_locale',
  INSERT_LOCALE_IN_URL: 'insert_locale_in_url',
};

const LOCALE_MAPPING = {
  LANGUAGE: 'lang',
  BROWSER: 'browser',
};

export {
  AUTHORIZE_URL_PATH,
  AUTODESK_TEXT,
  BASE_URL,
  COLLAPSE_TRANSITION_TIMEOUT,
  DEVELOPER_API_BASE_URL,
  ENDPOINTS,
  ENV,
  ENVIRONMENTS,
  FADE_TIMEOUT,
  IDP_OPT_OUT,
  INPUT_FOCUS_DELAY_MS,
  LINK_LOCALISATION_PATTERN,
  LOCALE_MAPPING,
  OAUTH_CODE_CHALLENGE_METHODS,
  OAUTH_PARAMS,
  OAUTH_PROMPTS,
  OAUTH_RESPONSE_TYPES,
  TRANSITION_CONFIG,
  UI_LOCALE_COOKIE,
  UI_PROMPTS,
  URL_DOMAIN_ENV,
  URL_PARAMS,
  WELL_KNOWN_CONFIG_PATH,
};
