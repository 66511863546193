import { AUTH_STATUS, HISTORY_ACTIONS } from 'appConstants/auth';
import { UI_PROMPTS } from 'appConstants/common';
import { ESSO_ERROR_STATUS } from 'appConstants/error';
import { AUTH_DEVICES } from 'appConstants/mfaConstants';
import { ROUTES } from 'appConstants/routing';
import { Dispatch, SetStateAction } from 'react';
import { AuthDevices, Device, StateUpdatingPromiseRef } from 'types';

/**
 * A utility function to retry a method n times
 * @param method the method to be retried
 * @param numberOfRetries number of retries
 * @returns a method that retries the original method n times
 */
export const withRetry = <T>(
  method: (...args: any) => Promise<T>,
  numberOfRetries = 1,
): ((...args: any) => Promise<T>) => {
  /**
   * The counter is initialized with the number of retries + 1 (to account for the original call)
   */
  let counter = numberOfRetries + 1;
  return async (...args: any): Promise<T> => {
    let errorObj: any;
    while (counter > 0) {
      try {
        return await method.apply(this, args).then((value: T) => {
          counter = 0;
          return value;
        });
      } catch (error) {
        counter--;
        errorObj = error;
      }
    }
    /**
     * If the method fails after n retries, reject the promise with the last error
     */
    return Promise.reject<T>(errorObj);
  };
};

/**
 * Extract the last 4 digits from a mobile number.
 * The backend sends mobile numbers in a masked format where the first part is masked with asterisks (*).
 * For example, a mobile number might be sent as `********89`.
 * This function removes all asterisks and returns the last 4 digits or fewer as per unmasked digits sent by user.
 * Currently backend sends only last 2 digits of mobile number in unmasked format.
 */
export const getLastMobileDigits = (mobileNumber: string) => {
  const mobileNumberDigits = mobileNumber.replace(/\*/g, '');
  return mobileNumberDigits.slice(-4);
};

export const getEnvironment = (ENVIRONMENTS: { [key: string]: string }): string => {
  const hostname = window.location.hostname;
  if (hostname.includes('-dev.autodesk.com') || hostname.includes('-local.autodesk.com')) {
    return ENVIRONMENTS.DEV;
  } else if (hostname.includes('-staging.autodesk.com') || hostname.includes('-stg.autodesk.com')) {
    return ENVIRONMENTS.STG;
  }
  return ENVIRONMENTS.PROD;
};

export const goToLinkUrl = (url: string): void => {
  if (!url) return;
  window.location.href = url;
};

const base64ToBytes = (base64: string) => {
  try {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0) as number);
  } catch (error) {
    return new Uint8Array();
  }
};

const bytesToBase64 = (bytes: Uint8Array) => {
  try {
    const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
    return btoa(binString);
  } catch (error) {
    return '';
  }
};

export const encodeBase64 = (data: string) => {
  /**
   * Refer: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
   */
  return bytesToBase64(new TextEncoder().encode(data));
};

export const decodeBase64 = (data: string) => {
  /**
   * Refer: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
   */
  return new TextDecoder().decode(base64ToBytes(data));
};

export const getFragmentParam = (paramName: string, urlString = window.location.href) => {
  const url = new URL(urlString);
  const fragment = url.hash;
  const fragmentParams = new URLSearchParams(fragment.slice(1));
  return fragmentParams.get(paramName);
};

export const getQueryParam = (param: string, urlString = window.location.href) => {
  const url = new URL(urlString);
  return url.searchParams.get(param);
};

export const setFragmentParamInUrl = (paramName: string, paramValue: string, url: string = window.location.href) => {
  const urlObj = new URL(url);
  const fragment = urlObj.hash;
  const fragmentParams = new URLSearchParams(fragment.slice(1));
  fragmentParams.set(paramName, paramValue);
  urlObj.hash = `#${fragmentParams.toString()}`;
  return urlObj.href;
};

export const isGlobalError = () => {
  /* When globalError is true, don't show the background image. True in following cases:
   * When there's no flowId in URL and its a base route OR
   * When it's an unknown route
   */
  const url = new URL(window.location.href);
  const openRoutes = [ROUTES.BASE, ROUTES.DOWNLOAD, ROUTES.SAML_ASSERTION_ERROR, ROUTES.REQUEST_ERROR];
  const noFlowId = !url.searchParams.has('flowId') && openRoutes.includes(url.pathname);
  const unknownRoute = !Object.values(ROUTES).includes(url.pathname);
  return noFlowId || unknownRoute;
};

export const shouldHandleBrowserBack = (status: string, devices: Device[] | undefined) => {
  /**
   * Should handle popstate if the status is PASSWORD_REQUIRED or OTP_REQUIRED (in case of CCPA flows)
   * or if the status is EMAIL_OTP_REQUIRED
   * Should not handle popstate if the status is OTP_REQUIRED and flow is MFA
   * Hence checking for devices to not be present in response, devices exist only in MFA flow
   */
  const isCcpaOtpRequired = status === AUTH_STATUS.OTP_REQUIRED && !devices;
  const isOtpRequiredStatus = isCcpaOtpRequired || status === AUTH_STATUS.EMAIL_OTP_REQUIRED;
  if (status === AUTH_STATUS.PASSWORD_REQUIRED || isOtpRequiredStatus) {
    return true;
  }
  return false;
};

export const removeHtmlTags = (text: string) => {
  return text.replace(/<\d+>|<\/\d+>/g, '');
};

export const updateRoute = (route: string, actionType = HISTORY_ACTIONS.REPLACE) => {
  const url = new URL(window.location.href);
  url.pathname = route;
  window.history[actionType === HISTORY_ACTIONS.PUSH ? 'pushState' : 'replaceState'](null, '', url.toString());
};

export const getEventLocation = (flow: string, screen: string) => {
  return `${flow}-${screen}`;
};

export const checkInviteUser = (uiPrompts: Array<string> | undefined) => {
  if (!uiPrompts) return false;
  return uiPrompts.includes(UI_PROMPTS.ACTIVATE_ACCOUNT) || uiPrompts.includes(UI_PROMPTS.SHOW_TOS);
};

export const getSelectedDeviceType = (authDevices: AuthDevices | undefined) => {
  return authDevices?.devices.find((device: Device) => device.id === authDevices.selectedDeviceId)
    ?.type as keyof typeof AUTH_DEVICES;
};

export const isLogoutFlow = () => {
  return [ROUTES.LOGOUT, ROUTES.LOGGED_OUT].includes(window.location.pathname);
};

export const isSAMLErrorFlow = () => {
  return ROUTES.SAML_ASSERTION_ERROR.includes(window.location.pathname);
};

export const isIdsdkFlow = () => {
  return window.location.pathname === ROUTES.IDSDK;
};

export const isESSOErrorFlow = (status = '') => {
  return ESSO_ERROR_STATUS.includes(status);
};

export const getFlowId = () => {
  const appUrl = new URL(window.location.href);
  return appUrl.searchParams.get('flowId') ?? '';
};

export const updateStateAndStateUpdatingPromiseRef = <T>(
  newValue: T,
  updater: Dispatch<SetStateAction<T>>,
  stateUpdatingRef: React.MutableRefObject<StateUpdatingPromiseRef>,
) => {
  // If the value is the same, don't update the state
  if (stateUpdatingRef.current.latestUpdatedValue === newValue) {
    return;
  }
  stateUpdatingRef.current.stateUpdating = true;
  stateUpdatingRef.current.promise = new Promise<void>((resolve) => {
    stateUpdatingRef.current.resolve = resolve;
    updater(newValue);
  });
};
